import Vue from 'vue';
import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { v4 } from 'uuid';

// Application
import GetInvestorProfileFlagshipProfilingQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import GetRetirementInvestorGoalQuery
  from '@/modules/flagship/retirement-investor-goal/application/queries/get-retirement-investor-goal-query';
import CalculateRetirementGoalMonthlyPaymentCalculationQuery
  from '@/modules/flagship/retirement-goal-calculations/application/queries/calculate-retirement-goal-monthly-payment-calculation-query';
import RetirementInvestorGoalCommand
  from '@/modules/flagship/retirement-investor-goal/application/commands/retirement-investor-goal-command';
import CreateRetirementForecastCalculationCommand
  from '@/modules/flagship/retirement-forecast-calculation/application/commands/create-retirement-forecast-calculation-command';

// Domain
import {
  RetirementInvestorGoalEntity,
} from '@/modules/flagship/retirement-investor-goal/domain/entities/retirement-investor-goal-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { minValueRule, requiredRule } from '@/vue-app/utils/form-rules';

export default class FlagshipGoalsWizardRetirementFundModel {
  @Inject(TYPES.GET_RETIREMENT_INVESTOR_GOAL_QUERY)
  private readonly get_retirement_investor_goal_query!: GetRetirementInvestorGoalQuery;

  @Inject(TYPES.CALCULATE_RETIREMENT_GOAL_MONTHLY_PAYMENT_CALCULATION_QUERY)
  private readonly calculate_retirement_goal_monthly_payment_calculation_query!:
    CalculateRetirementGoalMonthlyPaymentCalculationQuery;

  @Inject(TYPES.RETIREMENT_INVESTOR_GOAL_COMMAND)
  private readonly create_retirement_investor_goal_command!: RetirementInvestorGoalCommand;

  @Inject(TYPES.CREATE_RETIREMENT_FORECAST_CALCULATION_COMMAND)
  private readonly retirement_forecast_calculation!: CreateRetirementForecastCalculationCommand;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileFlagshipProfilingQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace =
    'components.flagship.flagship-goals.flagship_goals_wizard_retirement_fund';

  RETIREMENT_AGE_SLIDER_MAX_VALUE = 75;

  RETIREMENT_AGE_SLIDER_MIN_VALUE = 55;

  private retirement_investor_goal_entity: RetirementInvestorGoalEntity = {
    id: v4(),
    issued_age: 0,
    retirement_age_adjusted: 0,
    retirement_range_adjusted: '',
    pension_range_adjusted: 0,
    monthly_pension_adjusted: '',
    investor_goal: {
      investor_profile_id: '',
      initial_amount: 0,
      accumulated_amount: 0,
    },
  };

  private investor_profile_id = '';

  private issued_age!: number;

  retirement_range_adjusted = '';

  pension_range_adjusted = 0;

  monthly_pension_adjusted = '';

  monthly_payment = 0;

  minimum_monthly_pension_with_initial_balance = 1;

  required_amount_is_cover_by_initial_amount = false;

  is_valid_form = false;

  monthly_amount_for_retirement = '0';

  input_rules = {
    monthly_amount_for_retirement: [
      requiredRule,
      (value: string) => minValueRule(
        value.replace(/[^0-9.-]/g, ''),
        currencyFormat(1),
        1,
      ),
    ],
  };

  retirement_age = 50;

  retirement_projection = 0;

  readonly chart_options = {
    chart: {
      type: 'area',
      height: 220,
      stacked: true,
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    yaxis: {
      labels: {
        formatter(value: number) {
          return (String(value).length < 7) ? `${Math.floor(value / 1000)} k` : `${Math.floor(value / 1000)} M`;
        },
      },
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          colors: '#0047BA',
        },
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
        position: 'topRight',
      },
      custom({ series, seriesIndex, dataPointIndex }: never) {
        return `
          <div class="arrow_box">
            <span>${currencyFormat(series[seriesIndex][dataPointIndex])}</span>
          </div>
        `;
      },
    },
  };

  chart_series: Array<unknown> = [];

  private readonly view: Vue;

  timer?: NodeJS.Timer;

  is_loading = false;

  initial_amount = 0;

  initial_amount_adjusted = 0;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get monthly_payment_formatted() {
    return Math.trunc(this.monthly_payment).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  get monthly_pension_replaced() {
    return this.monthly_amount_for_retirement.toString().replace(/[^0-9.]/g, '');
  }

  get is_continue_btn_disabled() {
    return !(this.monthly_amount_for_retirement !== '0' && this.retirement_age !== 0
      && this.is_valid_form) || this.is_loading;
  }

  get retirement_projection_formatted() {
    return Math.trunc(this.retirement_projection).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  get retirement_range_adjusted_years() {
    return Number(this.retirement_range_adjusted) / 12;
  }

  get formatted_initial_amount_adjusted() {
    return currencyFormat(this.initial_amount_adjusted);
  }

  get formatted_min_monthly_pension() {
    return currencyFormat(this.minimum_monthly_pension_with_initial_balance);
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  getRetirementInvestorGoalDataIfExists = async () => {
    try {
      // eslint-disable-next-line max-len
      const { monthly_pension_adjusted, retirement_age_adjusted, investor_goal } = await this.get_retirement_investor_goal_query.execute(true);
      this.initial_amount = investor_goal.initial_amount || 0;
      this.initial_amount_adjusted = investor_goal.initial_amount_adjusted || 0;
      this.monthly_amount_for_retirement = String(Math.trunc(monthly_pension_adjusted));
      this.monthly_pension_adjusted = String(monthly_pension_adjusted);
      this.retirement_age = retirement_age_adjusted;
      await this.calculateRetirementAmountMonthlyWithInitialBalance();
      await this.calculateMonthlyPension();
    } catch (e) {
      if (!e.toString().includes('Error investor goal doesnt exist')) {
        this.messageNotifier.showErrorNotification(this.translate('error_message'));
      }
      // Default values for input v-model's if RetirementInvestorGoal does not exists
      this.monthly_amount_for_retirement = '0';
      this.retirement_age = 50;
    }
  }

  setRetirementChartValues = (chart_report: Record<string, number>) => {
    // chart_series is returned to its initial value to avoid overwriting more values to the chart.
    this.chart_series = [];
    this.chart_series.push({
      name: '',
      data: Object.keys(chart_report).map((value) => ({
        x: value,
        y: chart_report[value],
      })),
    });
  }

  getInvestorProfile = async () => {
    try {
      const { id } = await this.get_investor_profile_query.execute();
      this.investor_profile_id = id;
    } catch (e) {
      this.messageNotifier.showErrorNotification(this.translate('errors.get_investor_profile'));
    }
  };

  delay = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      await this.calculateMonthlyPension();
    }, 2000);
  }

  calculateRetirementAmountMonthlyWithInitialBalance = async () => {
    const retirement_forecast_dto = {
      investor_goal_id: '',
      initial_amount: this.initial_amount_adjusted,
      monthly_required_amount: 0.00,
      retirement_age_adjusted: this.retirement_age,
    };

    const forecast_calculation = await this.retirement_forecast_calculation
      .internalExecute(retirement_forecast_dto);
    const { monthly_pension_adjusted } = forecast_calculation
      .retirement_calculation_data;
    // eslint-disable-next-line max-len
    this.minimum_monthly_pension_with_initial_balance = monthly_pension_adjusted >= 1 ? monthly_pension_adjusted : 1;
  }

  validateMinimumAmountOnChangeDesiredAmount = () => {
    const amount = parseFloat(this.monthly_pension_replaced);
    if (amount >= 1 && amount < this.minimum_monthly_pension_with_initial_balance) {
      this.monthly_amount_for_retirement = String(Math.trunc(
        Math.floor(this.minimum_monthly_pension_with_initial_balance),
      ));
      this.required_amount_is_cover_by_initial_amount = true;
    } else {
      this.required_amount_is_cover_by_initial_amount = false;
    }
  }

  calculateMonthlyPension = async () => {
    try {
      this.is_loading = true;
      this.validateMinimumAmountOnChangeDesiredAmount();
      this.monthly_pension_adjusted = this.monthly_amount_for_retirement;
      const {
        retirement_chart_report,
        retirement_calculation_data,
        investor_profile_data,
      } = await this.calculate_retirement_goal_monthly_payment_calculation_query.execute({
        monthly_pension_adjusted: this.monthly_pension_replaced,
        retirement_age_adjusted: this.retirement_age,
        investor_profile_id: this.investor_profile_id,
        initial_amount: this.initial_amount_adjusted,
      });
      this.setRetirementChartValues(retirement_chart_report!);
      this.retirement_projection = retirement_calculation_data.accumulated_amount;
      this.issued_age = investor_profile_data.issued_age;
      this.RETIREMENT_AGE_SLIDER_MIN_VALUE = this.issued_age > this.RETIREMENT_AGE_SLIDER_MIN_VALUE
        ? this.issued_age + 1 : this.RETIREMENT_AGE_SLIDER_MIN_VALUE;
      this.monthly_payment = retirement_calculation_data.monthly_required_amount;
      this.retirement_range_adjusted = retirement_calculation_data.retirement_range_adjusted;
      this.pension_range_adjusted = retirement_calculation_data.pension_range_adjusted;
      this.monthly_pension_adjusted = retirement_calculation_data.monthly_pension_adjusted;
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.calculate_monthly_pension'));
    } finally {
      this.is_loading = false;
    }
  };

  createRetirementGoal = async () => {
    try {
      this.retirement_investor_goal_entity.issued_age = this.issued_age;
      this.retirement_investor_goal_entity.retirement_age_adjusted = this.retirement_age;
      this.retirement_investor_goal_entity.monthly_pension_adjusted = this.monthly_pension_adjusted;
      this.retirement_investor_goal_entity
        .retirement_range_adjusted = this.retirement_range_adjusted;
      this.retirement_investor_goal_entity.pension_range_adjusted = this.pension_range_adjusted;
      this.retirement_investor_goal_entity.investor_goal = {
        investor_profile_id: this.investor_profile_id,
        accumulated_amount: this.retirement_projection,
        monthly_required_amount: this.monthly_payment.toFixed(2),
      };

      // eslint-disable-next-line max-len
      await this.create_retirement_investor_goal_command.execute(this.retirement_investor_goal_entity);
      return true;
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.create_retirement_goal'));
      return false;
    }
  }

  initialize = async () => {
    this.is_loading = true;
    this.view.$emit('loadingStep', true);
    await this.getInvestorProfile();
    await this.getRetirementInvestorGoalDataIfExists();
    await this.calculateRetirementAmountMonthlyWithInitialBalance();
    this.view.$emit('loadingStep', false);
    this.is_loading = false;
  }

  onSubmit = async () => {
    this.is_loading = true;
    this.view.$emit('loadingStep', true);
    const successfully = await this.createRetirementGoal();
    this.view.$emit('loadingStep', false);
    this.is_loading = false;
    if (successfully) {
      this.view.$emit('nextStep');
    }
  }
}
